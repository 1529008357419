<template>
  <YLayoutBodyMenu>
    <el-card style="min-height: 45rem" v-loading="loading">
      <div>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="searchForm.nickname" placeholder="昵称"></el-input>
          </el-col>
          <el-col :span="16">
            <div class="flex-def flex-zBetween">
              <el-button-group>
                <el-button @click="searchGo" type="primary">搜索</el-button>
                <el-button @click="searchReset">重置</el-button>
              </el-button-group>
              <el-button @click="handsRegisterBox = true" type="primary">注册用户</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table row-key="id" :data="list">
        <el-table-column prop="id" width="80" label="编号"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column  label="昵称/头像" width="200" show-overflow-tooltip>
          <template #default="s">
            <div>
              <div>{{s.row.nickname}}</div>
              <div v-if="s.row.avatar">
                <img  :src="s.row.avatar | tomedia" style="width: 3rem;height: 3rem;border-radius: 2rem;" alt="">
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column  label="微信" width="200" show-overflow-tooltip>
          <template #default="s">
            <div>
              <div v-if="s.row.auth_official">公众号：{{ s.row.auth_official.openid }}</div>
              <div v-if="s.row.auth_mini">小程序：{{ s.row.auth_mini.openid }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column  prop="from_uid" label="注册邀请人">
          <template #default="s">
            <div v-if="s.row.from_user">
              <div class="y-desc">编号:<span style="color: initial">{{ s.row.from_user.id }}</span></div>
              <div class="y-desc">昵称:<span style="color: initial">{{ s.row.from_user.nickname }}</span></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column  label="注册邀请绑定">
          <template v-slot="s">
            <div>绑定时间：{{s.row.invite_bind_time | date}}</div>
            <div>关系锁定：{{s.row.invite_expire_lock ? "是" : "否"}}</div>
          </template>
        </el-table-column>
        <el-table-column  prop="credit" label="积分"></el-table-column>
        <el-table-column  prop="bonus" label="佣金"></el-table-column>
        <el-table-column  prop="total_cash" label="累计提现"></el-table-column>
        <el-table-column  prop="cost" label="消费账户"></el-table-column>

        <el-table-column width="200"  label="操作">
          <template v-slot="s">
            <YUserDetail :uid="s.row.id">
              <span class="y-pointer">详情</span>
            </YUserDetail>
            <div><el-button type="text" size="mini" @click="getLargeQrcode(s.row.id,false)">公众号网页码1280px</el-button></div>
            <div><el-button type="text" size="mini" @click="getLargeQrcode(s.row.id,true)">微信小程序码1280px</el-button></div>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
      <el-dialog title="注册用户" width="20rem" :visible.sync="handsRegisterBox" @closed="handsRegisterClosed">
        <el-form v-loading="registerLoading" :model="handsRegisterForm">
          <el-form-item label="手机号">
            <el-input v-model="handsRegisterForm.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="上级邀请人">
            <y-user-choose width-input v-model="handsRegisterForm.from_uid"></y-user-choose>
          </el-form-item>
          <el-form-item label="用户昵称">
            <el-input v-model="handsRegisterForm.nickname" autocomplete="off"></el-input>
            <span class="y-desc">为空时默认填充为 用户昵称</span>
          </el-form-item>
          <el-form-item label="用户头像">
            <y_upload_img v-model="handsRegisterForm.avatar"></y_upload_img>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handsRegisterBox = false">取 消</el-button>
          <el-button type="primary" @click="handsRegister">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </YLayoutBodyMenu>
</template>

<script>

import YUserDetail from "@/components/user/YUserDetail";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
import YUserChoose from "@/components/user/YUserChoose";
export default {
  name: "manage",
  components: {
    YUserChoose,
    YLayoutBodyMenu,
    YUserDetail,
    y_upload_img,
  },

  data() {
    return {
      registerLoading:false,
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm:{
        phone:"",
        nickname:"",
      },
      handsRegisterBox:false,
      handsRegisterForm:{
        avatar:"",
        phone :"",
        nickname :"",
        from_uid:0,
      },
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    getLargeQrcode(uid,isWxapp){
      this.loading = true;
      this.$u.api.card.userLargeQrcode({uid:uid,is_wxapp:isWxapp}).then(res=>{
        window.open(res,"_blank")
      }).finally(()=>{
        this.loading = false;
      })
    },
    handsRegisterClosed(){
      this.handsRegisterForm = {
        avatar:"",
        phone :"",
        nickname :"",
        from_uid: 0
      }
    },
    handsRegister(){
      if(this.registerLoading)return;
      this.registerLoading = true;
      this.$u.api.user.handsRegister(this.handsRegisterForm).then(()=>{
        this.handsRegisterBox = false;
        this.$message.success("操作成功");
        this.$nextTick(()=>{
          this.registerLoading = false;
        })
        this.searchGo();
      }).catch(()=>{
        this.registerLoading = false;
      })
    },
    searchGo(){
      this.page = 1;
      this.list.length =0;
      this.load();
    },
    searchReset(){
      this.searchForm = {
        phone:"",
        nickname:"",
      }
      this.searchGo()
    },
    load() {
      this.$u.api.user.search({
        page:this.page,
        nickname:this.searchForm.nickname,
        phone:this.searchForm.phone,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>